import React from 'react'
import Banner from '../../../../components/common/Banner'
import Layout from '../../../../components/layouts'
import PartnerGrid from '../../../../components/resources/partners/PartnerGrid'
import SEO from '../../../../components/common/SEO'

export const query = graphql`
query AllPartnersQuery ($after: String) {
  prismic {
    allPartners (first: 100 after: $after) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          _meta {
            uid
            id
          }
          partner_level
          name
          logo
          contact_info {
            city
            province
            search_terms
          }
        }
      }
    }
  }
}
`

// export const query = graphql`
// query AllPartnersQuery ($after: String) {
//   prismic {
//     allPartners (first: 25 after: $after) {
//       totalCount
//       pageInfo {
//         hasNextPage
//         endCursor
//       }
//       edges {
//         node {
//           _meta {
//             uid
//             id
//           }
//           partner_level
//           name
//           logo
//           contact_info {
//             city
//             province
//             search_terms
//           }
//         }
//       }
//     }
//   }
//   nextTwenty:prismic {
//     allPartners(after: "XnJ1ERIAACkADCId", first: 20) {
//       totalCount
//       pageInfo {
//         hasNextPage
//         endCursor
//       }
//       edges {
//         node {
//           _meta {
//             uid
//           }
//           partner_level
//           name
//           logo
//           contact_info {
//             city
//             province
//             search_terms
//           }
//         }
//       }
//     }
//   }
// }
// `



export default ({data}) => {
  // BANNER PROPS
  const title = () => (
    <div>
      <span>Let Our </span>Skilled<br /> Partners <span>Guide You</span>
    </div>
  )

  const bannerProps = {
    title: title,
    subtitle: "Partners",
    bannerImage: "/partners/partner-header.jpg",
    // buttonLeft: {
    //   text: "Become A Partner",
    //   link: "/about/careers"
    // },
    // buttonRight: {
    //   text: "What is a Partner",
    //   link: "/about/story"
    // }
  }

  const seo = {
    title: "Workhub | Our Partners | Free EHS Management Software",
    description:
      "Find a Workhub partner in your region that can help with implementation assistance, support, counselling & benefit from their industry expertise & safety consulting.",
  }

    // console.log(data)

  return(
    <Layout>
       <SEO {...seo}/>
        <Banner {...bannerProps}/>
        <PartnerGrid data={data}/>
    </Layout>
  )
}
